<template>
  <div class="content-recupery-password">
    <div class="content-recupery-password-itens container">
      <div class="content-recupery-password-itens-header">
        <h1>{{ $t('register.recupery_password') }}</h1>
      </div>
      <form class="content-recupery-password-itens-body">
        <div class="row">
          <div class="col-sm-8 col-lg-5 mx-auto">
            <div class="content-elements">
              <div class="content-elements-header">
                <h2>{{ $t('register.personal_information') }}</h2>
              </div>
              <form
                ref="formLogin"
                @submit.prevent="handleSubmit"
                method="post"
                class="content-elements-body"
              >
                <div class="row">
                  <div class="col-lg-12">
                    <div class="content-input">
                      <label>{{ $t('register.email_address') }}*</label>
                      <input
                        type="text"
                        name="email"
                        value=""
                        placeholder=""
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <button class="btn-create" type="submit">
                      {{ $t('register.recupery_password') }}
                    </button>
                    <router-link :to="{name: 'Login' }" class="btn-continue-shopping">
                      <img
                        class="img-fluid"
                        src="@/assets/img/arrow-left.png"
                        alt="arrow left"
                        title="arrow left"
                      />
                      {{ $t('btns_default.login_in') }}
                    </router-link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Login",
  metaInfo: {
    title: "Login",
  },
  components: {},
  computed: {},
  methods: {
    ...mapActions("User", ["recoverPass"]),
    async handleSubmit() {
      let formData = await this.$root.getAllData(this.$refs.formLogin);

      formData.birthday = this.birthday;

      if (this.$refs.formLogin.checkValidity()) {
        this.recoverPass(formData).then((response) => {
          this.$alert(
            "Consulte o seu email para proceder à alteração da Password."
          );
          this.$refs.formLogin.reset();
        });
      } else {
        this.$refs.formLogin.reportValidity();
      }
    },
  },
};
</script>

<style lang="scss">
.content-recupery-password {
  padding: 80px 0px;
  @media (max-width: 991px) {
    padding: 40px 0px;
  }
  &-itens {
    &-header {
      padding-bottom: 10px;
      border-bottom: 1px solid #ebebeb;
      h1 {
        font-size: 35px;
        font-family: "Font Bold";
        color: var(--main-color5);
        margin-bottom: 0;
        text-transform: uppercase;
        @media (max-width: 576px) {
          font-size: 28px;
        }
      }
    }
    &-body {
      padding-top: 45px;
      .content-elements {
        &-header {
          text-align: center;
          margin-bottom: 30px;
          h2 {
            font-size: 35px;
            font-family: "Font Bold";
            color: var(--main-color5);
            margin-bottom: 0;
            @media (max-width: 576px) {
              font-size: 28px;
            }
          }
        }
        &-body {
          .content-input {
            text-align: left;
            margin-bottom: 30px;
            label {
              display: block;
              font-size: 16px;
              font-family: "Font Regular";
              color: var(--main-color4);
              margin-bottom: 5px;
              line-height: 1;
            }
            input {
              height: 50px;
              width: 100%;
              border: 1px solid #ebebeb;
              background: #fff;
              outline: none;
              font-size: 16px;
              font-family: "Font Regular";
              color: var(--main-color4);
              padding-left: 20px;
            }
          }
        }
      }
      .btn-create {
        height: 55px;
        width: 100%;
        background: var(--main-color5);
        border: 1px solid var(--main-color5);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-family: "Font Bold";
        color: var(--main-color);
        text-transform: uppercase;
        margin-bottom: 30px;
        transition: 500ms;
        &:hover,
        &:focus {
          background: var(--main-color);
          color: var(--main-color5);
        }
        @media (min-width: 992px) and (max-width: 1199px) {
          font-size: 16px;
          height: 50px;
        }
        @media (max-width: 359px) {
          font-size: 16px;
          height: 45px;
        }
      }
      .btn-continue-shopping {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: 0;
        border: 0;
        font-size: 16px;
        font-family: "Font Bold";
        color: var(--main-color4);
        padding: 0;
        margin: 0 auto;
        text-decoration: none;
        transition: 500ms;
        &:hover,
        &:focus {
          cursor: pointer;
          opacity: 0.8;
        }
        img {
          margin-right: 5px;
        }
      }
    }
  }
}
</style>